import React from 'react'

const DashboardVideoEdit = () => {
  return (
    <React.Fragment>
      Video Create
    </React.Fragment>
  )
}

export default DashboardVideoEdit