import React from 'react'

const DashboardVideoCreate = () => {
  return (
    <React.Fragment>
      Video Create
    </React.Fragment>
  )
}

export default DashboardVideoCreate