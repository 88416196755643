export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';

// CHANNEL
export const CHANNELS_LOADED = 'CHANNELS_LOADED';
export const CHANNEL_LOADED = 'CHANNEL_LOADED';

// CATEGORY
export const CATEGORIES_LOADED = 'CATEGORIES_LOADED';
export const CATEGORY_LOADED = 'CATEGORY_LOADED';

// ARTICLE
export const ARTICLES_LOADED = 'ARTICLES_LOADED';
export const ARTICLE_LOADED = 'ARTICLE_LOADED';

// ARTICLE
export const SVIDEOS_LOADED = 'SVIDEOS_LOADED';
export const SVIDEO_LOADED = 'SVIDEO_LOADED';

// FILE
export const FILES_LOADED = 'FILES_LOADED';
export const FILE_LOADED = 'FILE_LOADED';

// SPINNER
export const SPINNING = 'SPINNING'

// VIDEO
export const NEWSS_LOADED = 'NEWSS_LOADED';
export const NEWS_LOADED = 'NEWS_LOADED';
export const MUSICS_LOADED = 'MUSICS_LOADED';
export const MUSIC_LOADED = 'MUSIC_LOADED';
export const VIDEOS_LOADED = 'VIDEOS_LOADED';
export const VIDEO_LOADED = 'VIDEO_LOADED';

// LOG
export const LOGS_LOADED = 'LOGS_LOADED';
export const ADMIN_LOGS_LOADED = 'ADMIN_LOGS_LOADED';